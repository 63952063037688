@use '../variables' as var;

$marker-hover-color: black;

.leaflet-marker-icon {
    &.marker-nominal {
        path, rect {
            fill: var.$nominal-status-color;
        }
    }

    &.marker-degraded {
        path, rect {
            fill: var.$degraded-status-color;
        }
    }

    &.marker-malfunctioning {
        path, rect {
            fill: var.$malfunctioning-status-color;
        }
    }

    &:hover {
        path, rect {
            fill: $marker-hover-color;
        }
    }
}

.marker-tooltip {
    background-color: var.$nominal-status-color;
    border-color: var.$nominal-status-color;

    &.leaflet-tooltip-right:before {
        border-right-color: var.$nominal-status-color;
    }

    &.leaflet-tooltip-left:before {
        border-left-color: var.$nominal-status-color;
    }

    opacity: 0.4;
    color: var.$primary-font-light-color;

    h1 {
        font-size: 1.3em;
    }

    h2 {
        font-size: 1.2em;
    }

    &.tooltip-degraded {
        background-color: var.$degraded-status-color;
        border-color: var.$degraded-status-color;

        &.leaflet-tooltip-right:before {
            border-right-color: var.$degraded-status-color;
        }

        &.leaflet-tooltip-left:before {
            border-left-color: var.$degraded-status-color;
        }
    }

    &.tooltip-malfunctioning {
        background-color: var.$malfunctioning-status-color;
        border-color: var.$malfunctioning-status-color;

        &.leaflet-tooltip-right:before {
            border-right-color: var.$malfunctioning-status-color;
        }

        &.leaflet-tooltip-left:before {
            border-left-color: var.$malfunctioning-status-color;
        }
    }
}