/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use './styles/dipla-palettes';
@use './app/shared/ui/user-authentication/user-authentication-theme.component.scss' as user-authentication;
@use './app/shared/ui/badge/badge-theme.component.scss' as dipla-badge;
@use './styles/variables' as var;
@use 'sass:map';
@import "bootstrap/scss/bootstrap";
@import './styles/highcharts/highcharts-theme';
@import './styles/highcharts/highcharts-wind-rose';
@import './styles/highcharts/highcharts-wind-direction';
@import './styles/highcharts/highcharts-wind-speed';
@import './styles/highcharts/highcharts-visibility';
@import './styles/ngb/custom-ngb';
@import './styles/leaflet/dipla-leaflet';
@import './styles/angular-material/custom-angular-material';

@include mat.core();

:root {
    box-sizing: border-box;
}

$dipla-theme: mat.m2-define-light-theme((
    color: (
        primary: mat.m2-define-palette(dipla-palettes.$dipla-primary-palette),
        accent: mat.m2-define-palette(dipla-palettes.$dipla-accent-palette),
        warn: mat.m2-define-palette(dipla-palettes.$dipla-warn-palette)
    )
));

$dipla-merged-theme: map.deep-merge($dipla-theme, (
    color: (
        background: (
            card: mat.m2-get-color-from-palette(dipla-palettes.$dipla-primary-palette, 50)
        )
    )
));
    
@include mat.all-component-themes($dipla-merged-theme);
@include user-authentication.theme($dipla-merged-theme);
@include dipla-badge.theme($dipla-merged-theme);


html, body { height: 100vh; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.no-data-row {
    td {
        padding: 10px 15px;
    }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
