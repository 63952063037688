@use '../variables' as var;
@import 'https://code.highcharts.com/css/highcharts.css';


:root {
    --highcharts-background-color: var.$primary-bg-color-with-opacity;
    --highcharts-color-0: var.$primary-color;
}

.highcharts-background {
    fill: var.$primary-bg-color-50;
}

.highcharts-container {
    font-family: Dosis, arial, helvetica, sans-serif;
    color:var.$primary-font-color;
    background-color: var.$primary-bg-color-with-opacity;
}

.highcharts-title,
.highcharts-subtitle,
.highcharts-yaxis .highcharts-axis-title {
    text-transform: uppercase
}

.highcharts-title {
    font-weight: 700;
    font-size: 1.3em
}

.highcharts-axis-labels {
    font-size: 1em
}

.highcharts-legend-item>text {
    font-size: 1.1em
}

.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 1px
}

.highcharts-tooltip-box {
    stroke-width: 0;
    fill: var.$primary-color;
}
