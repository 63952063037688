.custom-tooltip-class {
    .tooltip-inner {
        background-color: var.$primary-dark-color;
        font-size: 125%;
        max-width: none;
    }    
    &.degraded .tooltip-inner {
        background-color: var.$degraded-status-color;
    }
    &.malfunctioning .tooltip-inner {
        background-color: var.$malfunctioning-status-color;
    }
    &.bs-tooltip-end {
        .tooltip-arrow::before {
            border-right-color: var.$primary-dark-color;
        }
        &.degraded .tooltip-arrow::before {
            border-right-color: var.$degraded-status-color;
        }
        &.malfunctioning .tooltip-arrow::before {
            border-right-color: var.$malfunctioning-status-color;
        }
    } 
    &.bs-tooltip-start {
        .tooltip-arrow::before {
            border-left-color: var.$primary-dark-color;
        }
        &.degraded .tooltip-arrow::before {
            border-left-color: var.$degraded-status-color;
        }        
        &.malfunctioning .tooltip-arrow::before {
            border-left-color: var.$malfunctioning-status-color;
        }        
    }
    &.bs-tooltip-top {
        .tooltip-arrow::before {
            border-top-color: var.$primary-dark-color;
        }
        &.degraded .tooltip-arrow::before {
            border-top-color: var.$degraded-status-color;
        }            
        &.malfunctioning .tooltip-arrow::before {
            border-top-color: var.$malfunctioning-status-color;
        }            
    }
    &.bs-tooltip-bottom {
        .tooltip-arrow::before {
            border-bottom-color: var.$primary-dark-color;
        }
        &.degraded .tooltip-arrow::before {
            border-bottom-color: var.$degraded-status-color;
        }        
        &.malfunctioning .tooltip-arrow::before {
            border-bottom-color: var.$malfunctioning-status-color;
        }        
    }
}
