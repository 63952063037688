@use '@angular/material' as mat;

@mixin _apply-user-authentication-color($color) {
  .user {
    color: mat.m2-get-color-from-palette($color);
  }
}

@mixin color($color-config) {
  $primary-color: mat.m2-get-color-from-palette($color-config, primary);
  $accent-color: mat.m2-get-color-from-palette($color-config, accent);
  $warn-color: mat.m2-get-color-from-palette($color-config, warn);

  dipla-user-authentication.primary-user-authentication {
    @include _apply-user-authentication-color($primary-color);
  }

  dipla-user-authentication.accent-user-authentication {
    @include _apply-user-authentication-color($accent-color);
  }

  dipla-user-authentication.warn-user-authentication {
    @include _apply-user-authentication-color($warn-color);
  }
}

@mixin theme($theme-config) {
  $color-config: mat.m2-get-color-config($theme-config);

  @if $color-config != null {
    @include color($color-config);
  }
}
