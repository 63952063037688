@use '../variables' as var;
@import 'https://code.highcharts.com/css/highcharts.css';


dipla-card-wind-rose {
    highcharts-chart {
        top: 50%;
        left: 50%;
        width: 100%;
        height: 400px;
        display: flex;
        justify-self: center;
    }

    .highcharts-legend-title {
        &.highcharts-label text {
            fill: var.$primary-font-color;
            opacity: 0.5;
        }
    }
}