@use '@angular/material' as mat;
@use 'dipla-palettes';

$primary-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-primary-palette, 500);
$primary-light-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-primary-palette, 200);
$primary-dark-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-primary-palette, 900);
$secondary-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-accent-palette, 500);
$accent-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-red-palette, 500);
$nominal-status-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-primary-palette, 500);
$degraded-status-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-orange-palette, 900);
$malfunctioning-status-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-red-palette, 600);

$primary-font-color: mat.m2-get-color-from-palette(mat.$m2-blue-gray-palette, 900);
$primary-font-light-color: white;
$primary-font-dark-color: $primary-font-color;

$primary-bg-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-primary-palette, 200);
$primary-bg-color-100: mat.m2-get-color-from-palette(dipla-palettes.$dipla-primary-palette, 100);
$primary-bg-color-50: mat.m2-get-color-from-palette(dipla-palettes.$dipla-primary-palette, 50);
$primary-bg-color-with-opacity: rgba($primary-bg-color, 0.2);
$primary-bg-dark-color: mat.m2-get-color-from-palette(dipla-palettes.$dipla-primary-palette, 600);
