@use '../variables' as var;
@import 'https://code.highcharts.com/css/highcharts.css';


dipla-card-visibility {

    highcharts-chart {
        top: 50%;
        left: 50%;
        width: 100%;
        height: 400px;
        display: flex;
        justify-self: center;
    }
}
