@use '@angular/material' as mat;

@mixin _apply-badge-color($color) {
  .dipla-badge {
    background-color: mat.m2-get-color-from-palette($color);
    color: white;
  }
}

@mixin color($color-config) {
  $primary-color: mat.m2-get-color-from-palette($color-config, primary);
  $accent-color: mat.m2-get-color-from-palette($color-config, accent);
  $warn-color: mat.m2-get-color-from-palette($color-config, warn);

  dipla-badge.primary-badge {
    @include _apply-badge-color($primary-color);
  }

  dipla-badge.accent-badge {
    @include _apply-badge-color($accent-color);
  }

  dipla-badge.warn-badge {
    @include _apply-badge-color($warn-color);
  }
}

@mixin theme($theme-config) {
  $color-config: mat.m2-get-color-config($theme-config);

  @if $color-config != null {
    @include color($color-config);
  }
}
