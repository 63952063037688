@use 'src/styles/variables' as var;
@use '@angular/material' as mat;

.mdc-tab.mat-mdc-tab-link.mat-mdc-focus-indicator.active-link {
    border-bottom: 3px solid var.$primary-color;
}

.mat-mdc-dialog-content.mdc-dialog__content.wrapper {
    margin: 0;
    padding: 0;
}


.mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: #{var.$accent-color};
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
}
.mat-mdc-snack-bar-container.success {
      --mdc-snackbar-container-color: #{var.$primary-color};
}

.mat-drawer-side.mat-drawer.mat-sidenav.sidenav {
    border-right-style: none;
}

:root{
        @include mat.tooltip-overrides((
            container-color: var.$primary-bg-color,
            supporting-text-color: var.$primary-font-dark-color,
        ));
}

dipla-reports, dipla-wis2 {
    .mat-mdc-tab-links {
        flex-direction: column;
        align-items: flex-start;
    }
    &.mat-mdc-tab-group {            
        flex-direction: row;
    }
    
    mat-tab-header {
        border-bottom: none;
    }
    .mat-tab-header-pagination {
        display: none !important;
    }
    .mat-mdc-tab-labels {
        flex-direction: column;
        align-items: flex-start;
    }
    .mat-ink-bar {
        height: 100%;
        left: 98% !important;
    }
    .mat-tab-body-wrapper {
        flex: 1 1 auto;
    }

    .icon-th div {
        justify-content: center;
    }
}
