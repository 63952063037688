@use '../variables' as var;

@property --color-series-1 {
    syntax: "<color>";
    inherits: false;
    initial-value: var.$primary-dark-color;
  }

  @property --color-series-0 {
    syntax: "<color>";
    inherits: false;
    initial-value: var.$secondary-color;
  }

  dipla-card-runway, dipla-card-met-garden {

    .highcharts-root {
        
        transition: width 0.2s;
        width: 100%;
    }

    highcharts-chart {
        top: 50%;
        left: 50%;
        width: 100%;
        height: 300px;
        display: flex;
        justify-self: center;
    }
    
    .main-band {
        fill: transparent;
    }

    .ten-min-band {
        fill: var(--color-series-0);
        fill-opacity: 0.8;
    }

    .highcharts-yaxis-grid .highcharts-grid-line,
    .highcharts-yaxis-grid .highcharts-minor-grid-line {
        display: none;
    }

    .highcharts-container .highcharts-background {
        fill: transparent;
    }

    .highcharts-gauge-series.highcharts-series-0 .highcharts-dial {
        stroke: var(--color-series-0);
        stroke-width: 2px;
        fill: var(--color-series-0);
        fill-opacity: 0.8;
    }

    .highcharts-data-labels.highcharts-series-0.highcharts-gauge-series {
        display: none;
    }

    .highcharts-label-box.highcharts-data-label-box {
        display: none;
    }

    .highcharts-gauge-series.highcharts-series-1 .highcharts-dial {
        stroke: var(--color-series-1);
        stroke-width: 2px;
        fill: var(--color-series-1);
        fill-opacity: 0.8;
    }

    .highcharts-data-labels.highcharts-series-1.highcharts-gauge-series {
        text {
            fill: var(--color-series-1);
            fill-opacity: 1;
        }    
    }

    .highcharts-yaxis .highcharts-tick {
        stroke-width: 2px;
        stroke: #666;
    }

    .highcharts-yaxis .highcharts-minor-tick {
        stroke-width: 1px;
        stroke: #999;
    }

    .highcharts-container .highcharts-background {
        fill: transparent;
    }

    .highcharts-axis-labels.highcharts-yaxis-labels text {
        fill:var.$primary-font-color;
    }
}